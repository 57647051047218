import axios from "axios";
import { toPng } from "html-to-image";
import JsBarcode from "jsbarcode";
import { toDataURL } from "qrcode";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { CustomModal, ModalList } from "../../components/form/modal";
import { DevicesList } from "../../components/utilities/functions";
import { Loader } from "../../components/utilities/loader";
import DataTable from "../../components/utilities/table";
import { conf } from "../../conf";
import {
  bindDelete,
  generateImage,
  getProductBindingList,
  getTemplatePreview,
} from "../../store/product/productService";
import {
  getTemplateImageInfo,
  getTemplateList
} from "../../store/template/templateService";
import { Types } from "../../store/types";
import "./lotTemplate.css";
import "./template.css";
import "./template296128.css";
import "./template416240.css";
import "./template960640.css";
let unAllocatedCount = 0;

export default function ListProductBinding() {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    data: [],
    dropItem: 0,
    updateTime: true,
    data2: [],
    selected: "all",
    date: new Date(),
  });
  const [checkbox, setCheckBox] = useState([]);
  const [modal, setModal] = useState({
    deleteModal: false,
    deleteData: {},
    viewData: {},
    viewModal: false,
    pushModal: false,
    pushData: {},
    pushModalSize: "xl",
    bulkModal: false,
    htmlCode: "",
  });
  const [html, setHTML] = useState("");
  const [image, setImage] = useState({ loading: false });
  const [productId, setProductId] = useState("");
  const [tagId, setTagId] = useState("");
  const [imgSize, setSize] = useState("");
  const [isAdded, setAdded] = useState(false);
  const [javaTemplate, setJavaTemplate] = useState(false);
  const [baseString, setBaseString] = useState();
  const [loading, setLoading] = useState(false);

  const imgref = useRef(null);
  const { productBindLoading, bindingdeleteLoading, productBindList } =
    useSelector((state) => state.products);
  const { templateData, loading: templateLoading } = useSelector(
    (state) => state.templates
  );

  useEffect(() => {
    dispatch(getProductBindingList());
    // dispatch(getTemplateFiles());
    dispatch(getTemplateList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let groupedData = groupBy(productBindList, function (item) {
      // return [item.price, item.category, item.quantity];
      return [item.tagId];
    });

    const data = groupedData?.map((val, i) => {
      let products = val.map((o) => o.productName);
      if (val.isImagePushed) unAllocatedCount = +1;
      return {
        no: i + 1,
        productName: products,
        tagName: val[0]?.tagName,
        templateFile: val[0]?.templateFileUrl,
        id: val[0]?.productTagMapId,
        productId: val[0]?.productId,
        isImagePushed: val[0]?.isImagePushed,
        htmlCode: val[0]?.htmlCode,
        templateId: val[0]?.templateId,
        imageInfo:
          groupedData[i]?.length > 1 && groupedData[i].imageInfo
            ? [
              Object.assign(val[0]?.imageInfo, { pos: val[0].position }),
              Object.assign(val[1]?.imageInfo, { pos: val[1].position }),
            ]
            : [val[0]?.imageInfo],
        templateUrl: val[0]?.templateFileUrl,
        size: val[0]?.imageInfo?.imageSize,
        templateImage: val[0]?.templateImage,
        templateFileID: val[0]?.fieName,
        tagId: val[0]?.tagId,
        noOfProduct: val[0]?.noOfProduct,
        basicTemplate: val[0]?.basicTemplate,
      };
    });
    setState((state) => ({ ...state, data: data, data2: data }));
    setAdded(false);
  }, [productBindList]);

  useEffect(() => {
    if (state.templateFileID === "2") {
      if (!image.loading) {
        const price = document.getElementById("p-new-price");
        const des = document.getElementById("p-description");
        const body = document.getElementById("p-body");
        if (html && price && des && !isAdded && body) {
          price.className =
            price?.innerText.length >= 5
              ? `${price.className} PfontSize `
              : price.className;
          const dFontSize =
            des.innerText.length <= 50
              ? `${des.className} dFontSize-30`
              : des.innerText.length <= 120
                ? `${des.className} dFontSize-120`
                : des.className;
          des.className = dFontSize;
          setAdded(true);
        }
      }
    }
  }, [image.loading]);

  const header = [
    {
      headerName: "#",
      editable: false,
      resizable: true,
      maxWidth: 100,
      cellRendererFramework: (param) => {
        return (
          <React.Fragment>
            {param.data.isImagePushed ? (
              <>
                <label className="form-check-label">{param.data.no}</label>
              </>
            ) : (
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  style={{ marginTop: "0.8rem" }}
                  type="checkbox"
                  onChange={() => handleClick(param.data.id)}
                  checked={checkbox.includes(param.data.id) ? true : false}
                />
                <label className="form-check-label">{param.data.no}</label>
              </div>
            )}
          </React.Fragment>
        );
      },
    },
    { headerName: "Size", field: "size", editable: false, resizable: true },
    {
      headerName: "Product",
      field: "productName",
      editable: false,
      resizable: true,
    },
    {
      headerName: conf.binding.tagId,
      field: "tagName",
      editable: false,
      resizable: true,
    },
    {
      headerName: "Template",
      editable: false,
      minWidth: 400,
      cellRendererFramework: (param) => {
        return (
          <React.Fragment>
            {/* {<div
                            dangerouslySetInnerHTML={{ __html: getImage(param.data) }}
                        />} */}
            <img
              src={`data:image/png;base64,${param.data?.templateImage}`}
              alt={"template"}
              style={{ padding: "10px", borderWidth: "1px", borderStyle: "solid", borderColor: "#ebebeb", marginBottom: "5x" }}
              width={param.data?.basicTemplate ? "50%" : "30%"}
            />
            {/* <img src={param.data?.templateUrl} alt={'template'} style={{ padding: '10px' }} /> */}
          </React.Fragment>
        );
      },
    },
    {
      headerName: "Action",
      editable: false,
      minWidth: 180,
      cellRendererFramework: (param) => {
        return (
          <React.Fragment>
            {/* <i className='fa fa-info-circle text-primary icon-padding icon16' onClick={() => viewHandle(param.data)} /> */}
            <img
              className="pointer icon-padding"
              src={"/img/info.png"}
              alt={"info"}
              onClick={() => viewHandle(param.data)}
              width={22}
            />

            <Link to={"/product-binding-edit/" + param.data.tagId}>
              <img
                className="pointer icon-padding"
                src={"/img/edit.png"}
                alt={"edit"}
                width={22}
              />
            </Link>
            {/* <i className='fa fa-trash text-danger icon-padding' onClick={() => modalHandle(param.data)} /> */}
            <img
              className="pointer icon-padding"
              src={"/img/delete.png"}
              alt={"delete"}
              onClick={() => modalHandle(param.data)}
              width={22}
            />
            {param.data.isImagePushed || templateLoading ? (
              <></>
            ) : (
              <div>
                <button
                  className="btn btn-theme btn-sm"
                  onClick={() => pushModal(param.data)}
                >
                  <i className="fa fa-upload" /> {conf.binding.update}
                </button>
              </div>
            )}
          </React.Fragment>
        );
      },
    },
  ];

  const handleClick = (id) => {
    if (checkbox.includes(id)) {
      const checkboxItems = JSON.parse(JSON.stringify(checkbox));
      const check = checkboxItems.findIndex((o) => o === id);
      checkboxItems.splice(check, 1);
      setCheckBox(checkboxItems);
    } else {
      setCheckBox([...checkbox, id]);
    }
  };

  const handleAllClick = (id) => {
    if (!state.all) {
      const data = state.data.filter((o) => !o.isImagePushed).map((i) => i.id);
      setCheckBox(data);
      setState((state) => ({ ...state, all: true }));
    } else {
      setCheckBox([]);
      setState((state) => ({ ...state, all: false }));
    }
  };

  const pushModal = async (data) => {
    setModal((modal) => ({
      ...modal,
      pushData: data,
      pushModal: true,
      pushModalSize: "lg",
    }));
    setImage((image) => ({ ...image, loading: true }));
    setTagId(data.tagId);
    dispatch(getTemplatePreview({ tagId: data.tagId })).then((resp) => {
      setImage((image) => ({ ...image, loading: false }));
      // setJavaTemplate(true);

      if (resp.data.javaTemplate) {
        setJavaTemplate(true);
        setBaseString(resp.data?.baseString);
        // htmlTemplate(data);
        // setBaseString(null);
      }
      //  else {
      //   htmlTemplate(data);
      //   setJavaTemplate(false);
      //   setBaseString(null);
      // }
    });
  };

  const htmlTemplate = (data) => {
    const template = templateData.find((o) => o.templateId === data.templateId);
    const size = template?.templateInfo?.replace("inch", "")?.trim();
    setSize(size);
    if (size === "7.5" || size === "10") {
      setLotTemplates(template, data);
    } else if (size === "2.9" || size === "3.7") {
      set296128Template(template, data);
    } else if (template.noOfProduct === 2) {
      setSplitTemplate(template, data);
    } else if (template?.basicTemplate) {
      setHTML("");
      const size = template?.templateInfo?.replace("inch", "")?.trim();
      setSize(size);
      if (template) {
        setImage((image) => ({ ...image, loading: true }));
        setProductId(data.productId);
        setTagId(data.tagId);

        dispatch(getTemplateImageInfo(data.productId)).then((resp) => {
          let html = template.htmlCode.toString();
          const data = resp.data?.[0];
          const newprice = (
            data.productPrice -
            (data.productDiscount / 100) * data.productPrice
          ).toFixed(2);
          const prices = newprice.split(".");
          const footnoteSplitted = data.footNote.split("\n");
          html = html
            .replace("#TITLE", data?.productName)
            .replace("#DESCRIPTIONPREVIEW", data?.discription)
            .replace("#DESCRIPTION", data?.discription)
            .replace("#OLDPRICE", "$" + data?.productPrice)
            .replace("#DISCOUNT", (data?.productDiscount ?? 0) + "%")
            .replace("#QRLINK", `data:image/png;base64,${data?.barcode}`)
            .replace(
              '<img src="#QRLINK" class="qr">',
              `<img src="data:image/png;base64,${data?.barcode}" width='55' height='26' class="qr">`
            )
            .replace("#POLYGONLINK", "/img/polygon.png")
            .replace("#NEWPRICE", prices[0])
            .replace("#PHONE", "")
            .replace("#SUBPRICE", `.${prices[1]}`)
            .replace("#STOCK", "")
            .replace("#QUANTITY", data?.quantity)
            .replace("#VALIDDATE", "")
            .replace("#PARTNO", data.id)
            .replace("#LOTNO", data.productName)
            .replace("#FOOTNOTEPREVIEW1", footnoteSplitted?.[0] ?? "")
            .replace("#FOOTNOTEPREVIEW2", footnoteSplitted?.[1] ?? "")
            .replace("#FOOTNOTEPREVIEW3", footnoteSplitted?.[2] ?? "")
            .replace("#FOOTNOTEPREVIEW4", footnoteSplitted?.[3] ?? "");
          // .replace("#VALIDDATE", data?.footNote)

          const qrText = data.id + data.productName;
          toDataURL(qrText ?? "0", (error, data) => {
            if (error) console.error(error);
            html = html.replace("#QRCODELINK", data);
            setHTML(html);
          });
          setHTML(html);

          setImage((image) => ({ ...image, loading: false }));
        });
        setState((state) => ({
          ...state,
          updateTime: true,
          date: new Date(),
        }));
      }
      setModal((modal) => ({
        ...modal,
        pushData: data,
        pushModal: true,
        pushModalSize: "lg",
        htmlCode: template?.htmlCode,
      }));
    } else {
      setArrowTemplates(template, data);
    }
  };

  const setLotTemplates = async (template, data) => {
    setModal((modal) => ({
      ...modal,
      pushData: data,
      pushModal: true,
      pushModalSize: "xl",
      htmlCode: template?.htmlCode,
    }));
    setHTML("");
    if (template) {
      setImage((image) => ({ ...image, loading: true }));
      setProductId(data.productId);
      setTagId(data.tagId);
      const productInfo = await dispatch(getTemplateImageInfo(data?.productId));
      setImage((image) => ({ ...image, loading: false }));

      setProductId(data.productId);
      setTagId(data.tagId);
      let html = template.htmlCode.toString().replace(/\\/, "");
      const tempdata = data.imageInfo[0];
      const barcode1 = textToBase64Barcode(tempdata?.id, "title", 800);
      const barcode2 = textToBase64Barcode(tempdata?.productName, "title", 800);
      // const barcode3 = textToBase64Barcode(tempdata?.quantity, 'quantity');
      html = html
        .replace("#PART", "Part Number:")
        .replace("#LOT", "Lot Number:")
        .replace("#DATE", `Initial / Date:`)
        .replace("#PARTVALUE", tempdata.id)
        .replace("#LOTVALUE", tempdata.productName)
        .replace("#DATEVALUE", tempdata.footNote)
        .replace("#BARCODE1URL", barcode1)
        .replace("#BARCODE2URL", barcode2);
      setHTML(html);

      setState((state) => ({ ...state, updateTime: true, date: new Date() }));
    }
  };

  const setSplitTemplate = async (template, data) => {
    setHTML("");
    setModal((modal) => ({
      ...modal,
      pushData: data,
      pushModal: true,
      pushModalSize: "lg",
    }));
    setImage((image) => ({ ...image, loading: true }));
    const size = template?.templateInfo?.replace("inch", "")?.trim();
    setSize(size);
    setProductId(data.productId);
    setTagId(data.tagId);

    const products = productBindList.filter((o) => o.tagId === data.tagId);
    const final = products.sort((a, b) => {
      return a.position - b.position;
    });

    const prod1 = await dispatch(getTemplateImageInfo(final[0]?.productId));
    const prod2 = await dispatch(getTemplateImageInfo(final[1]?.productId));

    let html = template.htmlCode.toString();

    if (prod1.status === 200) {
      const data = prod1.data?.[0];
      const newprice = (
        data.productPrice -
        (data.productDiscount / 100) * data.productPrice
      ).toFixed(2);
      const prices = newprice.split(".");
      html = html
        .replace("#TITLE1", data?.productName)
        .replace("#DESCRIPTION1", data?.discription)
        .replace("#OLDPRICE1", "$" + data?.productPrice)
        .replace("#DISCOUNT1", (data?.productDiscount ?? 0) + "%")
        .replace("#QRLINK", `data:image/png;base64,${data?.barcode}`)
        .replace(
          '<img src="#QRLINK" class="qr">',
          `<img src="data:image/png;base64,${data?.barcode}" width='55' height='26' class="qr">`
        )
        .replace("#NEWPRICE1", prices[0])
        .replace("#PHONE", "")
        .replace("#SUBPRICE1", `.${prices[1]}`)
        .replace("#STOCK", "")
        .replace("#QUANTITY<", `${data?.quantity}<`)
        .replace("#VALIDDATE", "")
        .replace("#DESCRIPTION2", "");
      // .replace("#DESCRIPTION2", data?.footNote)
      // .replace("#VALIDDATE", data?.footNote)
      setHTML(html);
      setImage((image) => ({ ...image, loading: false }));
      setState((state) => ({
        ...state,
        updateTime: true,
        date: new Date(),
      }));
    }

    if (prod2.status === 200) {
      const data = prod2.data?.[0];
      const newprice = (
        data.productPrice -
        (data.productDiscount / 100) * data.productPrice
      ).toFixed(2);
      const prices = newprice.split(".");

      html = html
        .replace("#TITLE2", data?.productName)
        .replace("#DESCRIPTION3", data?.discription)
        // .replace("#OLDPRICE2", "$" + data?.productPrice)
        // .replace("#DISCOUNT2", (data?.productDiscount ?? 0) + "%")
        .replace("#QRLINK", `data:image/png;base64,${data?.barcode}`)
        .replace(
          '<img src="#QRLINK" class="qr">',
          `<img src="data:image/png;base64,${data?.barcode}" width='55' height='26' class="qr">`
        )
        .replace("#NEWPRICE2", prices[0])
        .replace("#PHONE", "")
        .replace("#SUBPRICE2", `.${prices[1]}`)
        .replace("#STOCK", "")
        .replace("#QUANTITY2", data?.quantity)
        .replace("#VALIDDATE", "")
        .replace("#SERIALNUMBER", "")
        .replace("#DESCRIPTION4", "");

      const qrText = data.qrCodeUrl !== "" ? data.qrCodeUrl : "0";
      toDataURL(qrText ?? "0", (error, data) => {
        if (error) console.error(error);
        html = html.replace("#SCANLINK", data);
        setHTML(html);
      });
      setHTML(html);
      setImage((image) => ({ ...image, loading: false }));
      setState((state) => ({
        ...state,
        updateTime: true,
        date: new Date(),
      }));
    }

    setModal((modal) => ({ ...modal, htmlCode: template?.htmlCode }));
  };

  const set296128Template = async (template, data) => {
    setModal((modal) => ({
      ...modal,
      pushData: data,
      pushModal: true,
      pushModalSize: "xl",
      htmlCode: template?.htmlCode,
    }));
    setHTML("");
    if (template) {
      setImage((image) => ({ ...image, loading: true }));
      setProductId(data.productId);
      setTagId(data.tagId);
      setImage((image) => ({ ...image, loading: false }));
      let html = template.htmlCode.toString();
      const tempdata = data.imageInfo[0];
      const newprice = (
        tempdata.productPrice -
        (tempdata.productDiscount / 100) * tempdata.productPrice
      ).toFixed(2);
      const prices = newprice.split(".");
      const footnoteSplitted = tempdata.footNote.split("\n");
      html = html
        .replace("#TITLE", tempdata?.productName)
        .replace("#DESCRIPTIONPREVIEW", tempdata?.discription)
        .replace("#DESCRIPTION", tempdata?.discription)
        .replace("#OLDPRICE", "$" + tempdata?.productPrice)
        .replace("#PREVCOST", "$" + tempdata?.productPrice)
        .replace("#OFFER", (tempdata?.productDiscount ?? 0) + "%")
        .replace("#QRLINK", `data:image/png;base64,${tempdata?.barcode}`)
        .replace(
          '<img src="#QRLINK" class="qr">',
          `<img src="data:image/png;base64,${tempdata?.barcode}" width='55' height='26' class="qr">`
        )
        .replace("#POLYGONLINK", "/img/polygon.png")
        .replace("#NEWCOST", prices[0])
        .replace("#PHONE", "")
        .replace("#PREVCOST", `.${prices[1]}`)
        .replace("#STOCK", "")
        .replace("#QUANTITY", tempdata?.quantity)
        .replace("#VALID", "")
        .replace("#SUBCOST", `.${prices[1]}`)
        .replace("#DISCOUNT", (tempdata?.productDiscount ?? 0) + "%")
        .replace("#OFF", "OFF")
        .replace("#FOOTNOTEPREVIEW1", footnoteSplitted?.[0] ?? "")
        .replace("#FOOTNOTEPREVIEW2", footnoteSplitted?.[1] ?? "")
        .replace("#FOOTNOTEPREVIEW3", footnoteSplitted?.[2] ?? "")
        .replace("#FOOTNOTEPREVIEW4", footnoteSplitted?.[3] ?? "");
      setHTML(html);

      const qrText = data.qrCodeUrl !== "" ? data.qrCodeUrl : "0";
      toDataURL(qrText ?? "0", (error, data) => {
        if (error) console.error(error);
        html = html.replace("#QRLINK", data);
        setHTML(html);
      });

      setState((state) => ({ ...state, updateTime: true, date: new Date() }));
    }
  };

  const setArrowTemplates = (template, data) => {
    setHTML("");
    const size = template?.templateInfo?.replace("inch", "")?.trim();
    setSize(size);

    if (template) {
      setImage((image) => ({ ...image, loading: true }));
      setProductId(data.productId);
      setTagId(data.tagId);

      dispatch(getTemplateImageInfo(data.productId))
        .then((resp) => {
          if (resp.status === 200) {
            let html = template.htmlCode.toString().replace(/\\/, "");
            const data = resp.data?.[0];
            const barcode1 = textToBase64Barcode(
              data?.productName,
              "title",
              "800"
            );
            const barcode2 = textToBase64Barcode(
              data?.discription,
              "title",
              "800"
            );
            const barcode3 = textToBase64Barcode(data?.quantity, "quantity");
            html = html
              .replace("#TITLE1", data?.productName)
              .replace("#TITLE2", data?.discription)
              .replace("#TITLE3", "Do Not Pick")
              .replace("#ARROWLINKRIGHTRED", "/img/red-arrow-right.png")
              .replace("#ARROWLINKLEFTRED", "/img/red-arrow-left.png")
              .replace("#ARROWLINKLEFT", "/img/arrow-left.png")
              .replace("#ARROWLINKRIGHT", "/img/arrow-right.png")
              .replace("#QUANTITY", "QTY: " + data?.quantity)
              .replace("#BARCODELINK1", barcode1)
              .replace("#BARCODELINK2", barcode2)
              .replace("#BARCODELINK3", barcode3);
            // html = html.replace("#BARCODELINK2", barcode1)
            setHTML(html);
            setImage((image) => ({ ...image, loading: false }));
          } else {
            dispatch({
              type: Types.ALERT_ERROR,
              payload: { message: "Failed" },
            });
          }
        })
        .catch((err) => {
          console.log("errr", err);
        });
      setState((state) => ({ ...state, updateTime: true, date: new Date() }));
    }
    setModal((modal) => ({
      ...modal,
      pushData: data,
      pushModal: true,
      pushModalSize: "lg",
      htmlCode: template?.htmlCode,
    }));
  };

  const selectAll = () => {
    // const templatedata = state.data.find(o=>o.id)
    const Ids = [];
    let scanLink = "";
    checkbox.forEach((val) => {
      const templatedata = state.data.find((o) => o.id === val);
      const data = templatedata.imageInfo.sort((a, b) => {
        return a.pos - b.pos;
      });

      // console.log(templatedata);

      if (data.length > 1) {
        const qrText =
          templatedata.qrCodeUrl !== "" ? templatedata.qrCodeUrl : "0";
        toDataURL(qrText ?? "0", (error, data) => {
          if (error) console.error(error);
          scanLink = data;
        });
      }
      // const prod = {
      //   tagIds: ,
      // };
      Ids.push(templatedata?.tagId);
    });

    axios({
      method: "post",
      url: "http://localhost:9091/cpe/authenticate/bulkPush",
      data: { 'tagIds': Ids },
      withCredentials: false,
      timeout: 3000,
    })
      .then(resp => {
        if (resp.status === 200) {
          dispatch({ type: Types.ALERT_SUCCESS, payload: { message: 'Updated Tags Successfully' } });
          // dispatch(postImageArray(resp.data));
        } else {
          dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Something went wrong' } });
          console.log("Error:", resp);
        }
      })
      .catch(err => {
        dispatch({ type: Types.ALERT_ERROR, payload: { message: 'Something went wrong' } });
        console.log("Error:", err);
      })

    // }
  };

  const viewHandle = (data) => {
    setModal((modal) => ({ ...modal, viewData: data, viewModal: true }));
  };

  const modalHandle = (data) => {
    setModal((modal) => ({ ...modal, deleteData: data, deleteModal: true }));
  };

  const closeHandle = (data) => {
    setModal((modal) => ({
      ...modal,
      deleteData: {},
      deleteModal: false,
      viewData: {},
      viewModal: false,
      pushData: {},
      pushModal: false,
      pushModalSize: "lg",
      bulkModal: false,
    }));
  };

  const deleteHandle = () => {
    if (modal.deleteData?.id) {
      const products = productBindList.filter(
        (o) => o.tagId === modal.deleteData?.tagId
      );

      if (products.length) {
        dispatch(bindDelete(products)).then((resp) => {
          if (resp.status === 200) {
            dispatch(getProductBindingList());
            setModal((modal) => ({
              ...modal,
              deleteModal: false,
              deleteData: {},
            }));
          }
        });
      }
    }
  };

  const handleFilter = (e) => {
    const key = e.target.id;
    const data = state.data2;
    switch (key) {
      case "unallocated":
        const filter = data.filter((o) => o.isImagePushed !== true);
        setState((state) => ({ ...state, data: filter, selected: key }));
        break;
      case "allocated":
        const allocated = data.filter((o) => o.isImagePushed);
        setState((state) => ({ ...state, data: allocated, selected: key }));
        break;
      case "all":
        setState((state) => ({ ...state, data: data, selected: key }));
        break;
      default:
        break;
    }
  };

  const onButtonClick = () => {
    let html = document.querySelector("#htmlImage");
    setLoading(true);
    const sizes = DevicesList.find((o) => o.size === Number(imgSize));
    const height = sizes?.height ?? 120;
    const width = sizes?.width ?? 250;
    // toPng(html, { filter: filter, height: height, width: width })
    if (html) {
      toPng(html, {
        quality: 1,
        skipAutoScale: true,
        pixelRatio: 1,
        height: height,
        width: width,
      })
        .then((dataUrl) => {
          const file = dataURLtoFile(dataUrl, `Template ${width}x${height}`);
          let form = new FormData();
          form.append("productImg", file);
          console.log(
            "EPO ",
            state.date,
            Math.round(state.date.getTime() / 1000),
            dataUrl
          );
          generateImageAPI(form);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      generateImageAPI("");
    }
  };

  const generateImageAPI = async () => {
    // setLoading(true);
    await dispatch(
      generateImage(
        tagId,
        // form,
        false,
        !state.updateTime,
        Math.round(state.date.getTime() / 1000)
      )
    ).then((resp) => {
      setLoading(false);
    });
  };

  const GetTemplateImage = useCallback(() => {
    if (image.loading) {
      return (
        <Fragment>
          <Loader /> Loading Image <br />
        </Fragment>
      );
    }
    if (javaTemplate) {
      return (
        <div>
          <img src={`data:image/png;base64,${baseString}`} alt="baseString" min-width="50%" className="previewimage" />
          <br />
        </div>
      );
    } else {
      return (
        <div
          ref={imgref}
          id="htmlImage"
          style={modal.pushData.size === "7.5 inch" || "10 inch" ? {} : null}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    }
  }, [baseString, html, image.loading, javaTemplate, modal.pushData.size]);

  return (
    <>
      <div className="row d-flex align-items-center mb-3 mb-md-2">
        <div className="col-md-6">
          <p className="medium ft-23 mb-2 mb-md-0">
            {conf.binding.bindingList} {" "}
            {productBindLoading || templateLoading ? <Loader /> : null}
          </p>
        </div>
      </div>

      <div className="row d-flex justify-content-end">
        <DataTable
          key={state.data}
          headers={header}
          data={state.data}
          pagination={true}
          globalSearch={true}
        >
          <div className="d-flex justify-content-between">
            <div>
              {state.data?.length > 0 ? (
                <span style={{ display: "flex" }}>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      id={
                        checkbox.length > 0 &&
                          checkbox.length + unAllocatedCount < state.data.length
                          ? "flexCheckIndeterminate"
                          : null
                      }
                      type="checkbox"
                      checked={
                        checkbox.length + unAllocatedCount === state.data.length
                      }
                      onChange={() => handleAllClick()}
                    />
                    <label className="form-check-label">Select All</label>
                  </div>
                  {checkbox.length > 0 ? (
                    <button
                      className="btn btn-sm btn-theme mb-2"
                      style={{ marginLeft: "1rem" }}
                      onClick={() =>
                        setModal((modal) => ({ ...modal, bulkModal: true }))
                      }
                    >
                      {conf.binding.updateTags}
                    </button>
                  ) : (
                    <></>
                  )}
                </span>
              ) : (
                <br></br>
              )}
            </div>
            <div className="col-12 col-md-5 col-lg-3 mb-3">
              <div className="d-flex text-nowrap position-absolute ">
                <label>
                  <input
                    id="all"
                    type={"radio"}
                    checked={"all" === state.selected}
                    onChange={(e) => handleFilter(e)}
                  />
                  &nbsp;All &nbsp;&nbsp;
                </label>
                <label>
                  <input
                    id="unallocated"
                    type={"radio"}
                    checked={"unallocated" === state.selected}
                    onChange={(e) => handleFilter(e)}
                  />
                  &nbsp;Not Updated&nbsp;&nbsp;
                </label>
                <label>
                  {" "}
                  <input
                    id="allocated"
                    type={"radio"}
                    checked={"allocated" === state.selected}
                    onChange={(e) => handleFilter(e)}
                  />
                  &nbsp;Updated
                </label>
              </div>
              {/* <Select options={filterOptions} onchange={(e) => handleFilter(e)} /> */}
            </div>
          </div>
        </DataTable>
      </div>

      {modal.viewModal ? (
        <CustomModal onclose={closeHandle}>
          <div className="row d-flex align-items-center mb-3 mb-md-5">
            <p className="medium ft-23 mb-2 mb-md-0"  > {conf.binding.binding} Detail </p>
            <div className="col-md-6 text-start text-md-end"></div>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-12 col-lg-10">
              <div className="row">
                <ModalList
                  title="Product"
                  value={modal.viewData?.productName}
                  size={12}
                />
                <ModalList
                  title={conf.binding.tagId}
                  value={modal.viewData?.tagName}
                  size={12}
                />
                <div className="d-flex bold">
                  Template:
                  <img
                    src={`data:image/png;base64,${modal.viewData?.templateImage}`}
                    alt={"template"}
                    style={{ padding: "10px" }}
                    width={400}
                  />
                </div>
              </div>
            </div>
          </div>
        </CustomModal>
      ) : null}

      {modal.deleteModal ? (
        <CustomModal onclose={closeHandle}>
          <p className="medium ft-23 mb-1">{`Are you Sure to Delete ${modal.deleteData?.productName} - ${modal?.deleteData?.tagName}?`}</p>
          <div className="text-center mb-5 mt-4">
            <span className="btn btn-secondary" onClick={closeHandle}>
              Close
            </span>
            <span className={`btn btn-danger`} onClick={deleteHandle}>
              {bindingdeleteLoading ? (
                <>
                  <Loader /> Loading
                </>
              ) : (
                "Delete"
              )}
            </span>
          </div>
        </CustomModal>
      ) : null}

      {modal.pushModal ? (
        <CustomModal
          size="xl"
          onclose={closeHandle}
          isLeft={true}
        >
          <p className="medium ft-23 mb-1">{`Are you Sure to Push ${modal.pushData?.productName} - ${modal?.pushData?.tagName}?`}</p>
          <div className="">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                checked={state.updateTime}
                onClick={() =>
                  setState((state) => ({ ...state, updateTime: true }))
                }
              />
              <label className="form-check-label">Update Now</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="checkbox"
                checked={!state.updateTime}
                onClick={() =>
                  setState((state) => ({ ...state, updateTime: false }))
                }
              />
              <label className="form-check-label">Update Later</label>
            </div>
            {!state.updateTime ? (
              <React.Fragment>
                <label className="mb-2">Select Date</label>
                <DatePicker
                  selected={state.date}
                  showTimeSelect
                  onChange={(date) =>
                    setState((state) => ({ ...state, date: date }))
                  }
                  dateFormat="MM/dd/yyyy h:mm:a"
                  timeFormat="HH:mm"
                  timeInputLabel="Time:"
                  minDate={new Date()}
                  // minTime={new Date()}
                  timeIntervals={15}
                />
              </React.Fragment>
            ) : null}
            <br />
            <br />

            <GetTemplateImage />

            <span className="btn btn-secondary mt-2" onClick={closeHandle}>
              Close
            </span>
            <button
              className="btn btn-theme mt-2"
              onClick={() => {
                if (!loading) {
                  generateImageAPI();
                  closeHandle(); // Close the modal after performing the action
                }
              }}
            >
              {loading ? `Loading...` : `Update Display`}
            </button>
            {/* <span className={`btn btn-theme`} onClick={pushImage}>{pushImageLoading ? <><Loader /> Loading</> : 'Update Tag'}</span> */}
          </div>
        </CustomModal>
      ) : null}

      {modal.bulkModal ? (
        <CustomModal onclose={closeHandle} size="lg" isLeft={true}>
          <div className={`row d-flex align-items-center mb-3 mb-md-5`}>
            <div className="col-md-9">
              <p
                className="medium ft-23 mb-2 mb-md-0"
                style={{ marginLeft: "2rem" }}
              >
                Are you sure want to push?
              </p>
            </div>
            <div className="col-md-6 text-start text-md-end">
              Selected Products: {checkbox.length}
            </div>
          </div>
          {/* <div className={`row d-flex`}> */}
          {/* <div className='col-6 mt-5'> */}
          {/* <Select label={'Select Store'} options={state.storeOptions} />
                            <div className="mb-5 mt-4 float-right">
                                <span className="btn btn-secondary float-right" onClick={closeHandle}>Close</span>
                            </div> */}
          {/* </div> */}
          <div className={`col-6 col-lg-6 scroll-content-tag`}>
            <div className=" d-flexrow mx-3">
              {checkbox.map((v, i) => {
                const data = state.data.find((o) => o.id === v);
                return (
                  <span className={"d-flex"}>
                    {++i}. {data?.productName} ({data?.tagName})
                  </span>
                );
                // return `${++i}. ${data?.serialNumber} (${data?.store}), `
              })}
            </div>
          </div>
          <div className={"d-flex justify-content-end"}>
            <span className="btn btn-secondary mt-2" onClick={closeHandle}>
              Close
            </span>
            <button
              className="btn btn-theme mt-2"
              onClick={selectAll}
            >
              Update Tags
            </button>
          </div>
          {/* </div> */}
        </CustomModal>
      ) : null}
    </>
  );
}

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

function arrayFromObject(obj) {
  var arr = [];
  for (var i in obj) {
    arr.push(obj[i]);
  }
  return arr;
}

function groupBy(list, fn) {
  var groups = {};
  for (var i = 0; i < list.length; i++) {
    var group = JSON.stringify(fn(list[i]));
    if (group in groups) {
      groups[group].push(list[i]);
    } else {
      groups[group] = [list[i]];
    }
  }
  return arrayFromObject(groups);
}

function textToBase64Barcode(text, type, size) {
  var canvas = document.createElement("canvas");
  let editedText =
    size === 800
      ? text
      : text !== "" || text
        ? text?.replace(/[^a-zA-Z0-9 ]/g, " ")
        : 0;
  editedText =
    size === 800 ? editedText : editedText ? editedText?.substring(0, 9) : " ";
  const titlelength =
    type === "title" ? (text.length < 5 ? 50 : text?.length < 7 ? 60 : 90) : 90;
  const desclength =
    type === "desc" ? (text.length < 5 ? 50 : text?.length < 7 ? 60 : 90) : 90;
  const height = {
    title: titlelength,
    desc: desclength,
    quantity: 60,
  };
  JsBarcode(canvas, editedText, {
    format: "CODE128",
    displayValue: false,
    height: height[type],
  });
  return canvas.toDataURL("image/png");
}
