export const conf = {
    env: 'mfg',
    branch: 'mfg',
    version: '2.4',
    api: 'https://api.portal-mfg.com',

    esltag: {
        upload: false,
        esltags: 'Display (H)',
        esltag: 'Display Tag',
        list: 'Display List',
        add: 'Add Display',
        detail: 'Display Detail',
        autoScan: 'Auto Scan',
    },

    product: {
        products: 'Update Display (H)',
        list: 'Display Info',
        add: 'Add Product',
        detail: 'Product Detail',

        id: 'Part Number',
        name: 'Field 2',
        store: 'Site',
        price: 'Price',
        storeName: 'Site Name',
        footNote: "Today's Date (DD/MM/YYYY)",
    },

    binding: {
        binding: 'Display Setup',
        bindingList: 'Display Preview List',
        updateTags: 'Update Tags',
        tagId: 'Display ID',
        lot: 'Field 2',
        update: 'Preview Update',
    },

    dash: {
        totalTags: 'Total Tags',
    },
}   
